'use strict';
const { queryFirst } = require('./domUtil');
const { setItemInLocalStorage } = require('./util');

window.addEventListener('load', (event) => {
    const urlParams = new URLSearchParams(window.location.search);
    const referrerID = urlParams.get('referrerID');
    const referrerErrorPage = queryFirst('#main-content');
    const invalidId = queryFirst('.invalid-referrer-id', referrerErrorPage);
    const maxedOut = queryFirst('.maxed-out-referrer', referrerErrorPage);
    const digitalUser = queryFirst('.loyalty-digital-refer-friend-modal', referrerErrorPage);
    if (referrerID && !invalidId && !maxedOut && !digitalUser) {
        setItemInLocalStorage('REFERRED_ID', referrerID);
    }
});